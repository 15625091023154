.BookTabs {

  .nav-item {
    background: $gray-200;
    border-color: $border-color;
    border-left: none;
    border-radius: 0;
    flex: 1;
    flex-basis: 25%;
    max-width: 25%;
    text-align: center;

    height: $bp-tab-height;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    color: $body-color;

    &.active {
      background: #fff;
      border-color: $border-color;
      border-top: 3px solid $accent-color;
      border-bottom: none;
      font-weight: bold;
      color: $accent-color;
    }

    &:last-child {
      border-right: none;
    }

    &:not(.active) {
      &:hover, &:active, &:focus {
        border-color: $border-color;
        background: $gray-300;
        border-left-color: $gray-300;
      }
    }

    &:focus {
      outline: none;
    }
  }

  ~ .tab-content {
    background: #fff;
    flex: 1;
    height: $bp-content-height;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    @include media-breakpoint-down(md) {
      height: auto;
      min-height: $bp-content-height;
    }

    .tab-pane.active {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .GalleryTab, .TasksTab {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
