.SlideContents {

  max-height: calc(#{$bp-content-height} - #{$bp-content-breadcrumbs-height} - #{$bp-content-tabs-height});
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-bottom: 160px;
  position: relative;

  @include media-breakpoint-down(md) {
    max-height: none;
  }

  &__floating {
    z-index: 100;
    position: fixed;
    right: 30px;
    bottom: 30px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .main-button {
      width: 70px;
      height: 70px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: $success;
      color: $white;
      font-size: 32px;
      border-radius: 50%;
      box-shadow: 0 0 12px rgba($black, 0.3);
    }

    .copy-button {
      background: $white;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      button {
        margin-bottom: 10px;
      }
    }
  }

  &__item {

    margin-bottom: 20px;
    display: flex;

    .DragHandle {
      margin-left: 10px;
      margin-top: 10px;
      cursor: grab;

      svg {
        color: $gray-500;
        transition: $transition-base;
      }

      &:hover {
        svg {
          color: $gray-600;
        }
      }
    }

    .Question, .Image, .Description, .DoubleImage {
      width: 100%;
      max-width: 750px;
      margin: auto;
    }

    .Image, .DoubleImage {
      display: flex;
      flex-direction: column;
    }

    .Media {
      &-file {
        display: flex;

        .button {
          min-width: 200px;
          margin-right: 20px;

          display: flex;
          align-items: center;


          button {
            width: 100%;
          }
        }

        strong {
          margin-top: 7px;
        }
      }
    }

    .item-type {

      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      //border-right: 1px solid $card-border-color;
      border-left: 3px solid;
      background: $gray-100;
      margin-right: 20px;
      padding: 0 20px 0 10px;

      svg {
        margin: 0;
        margin-right: 10px;
      }

      &.i {
        border-left-color: lighten($purple, 20%);

        svg {
          color: lighten($purple, 20%);
        }

        .QuestionTypeLabel {
          background: lighten($purple, 40%);
          color: $black;
        }
      }

      &.d {
        border-left-color: $secondary;

        svg {
          color: $secondary;
        }

        .QuestionTypeLabel {
          background: lighten($secondary, 40%);
          color: $black;
        }
      }

      &.q {
        border-left-color: $blue--light;

        svg {
          color: $blue--light;
        }

        .QuestionTypeLabel {
          background: lighten($blue--light, 40%);
          color: $black;
        }
      }
    }
  }
}
