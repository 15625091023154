.card {
  flex: 1;
  width: 100%;
  margin-bottom: -1px;

  .hide {
    display: none;
  }

  &-body {
    display: flex;
    flex-direction: column;

    border-top: 1px solid $gray-300;

    padding: 30px;
    background: $gray-100;
  }

  &-form {
    padding: 30px;
    flex: 1;
    background: $gray-100;

    .form-group {
      margin-bottom: 30px;
    }
  }

  &-header {
    display: flex;
    align-items: center;

    background: $gray-200;
    font-weight: 500;
    border-bottom: 0;
    cursor: pointer;
    padding: 0;

    svg {
      margin-left: 20px;
      color: $gray-500;
      background: transparent !important;
    }

    &:hover {
      background: $gray-300;

      .title::after {
        @include gradient-x(rgba(255, 255, 255, 0), $gray-300);
      }
    }

    .title {
      flex: 1;
      height: 24px;
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;

        @include gradient-x(rgba(255, 255, 255, 0), $gray-200);
      }
    }
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0;
    background: transparent;
    border-top: none;
  }
}
