.SlideDropdown {

  svg {
    margin-left: 1em;
  }

  .dropdown-toggle {
    &::after {
      display: none;
    }
  }

  .dropdown-menu.show {
    display: block;
    right: 100% !important;
    left: auto !important;
    transform: none !important;
    bottom: 0 !important;
    top: auto !important;
    margin-bottom: 10px !important;
    margin-right: 5px !important;
  }
}
