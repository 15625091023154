.TasksTab {
	position: relative;

	.Editor {
		border: none;
	}

	.TaskDetail {
		padding-right: 30px;
	}

	.TaskMap {

		#mapContainer {
			height: 300px;
			width: 100%;
		}
	}

	.TaskDelete, .TaskAddSlide {
		margin-top: 15px;
	}
}
