.placeholder {

	color: $gray-500;
	height: 100%;
	display: flex;

	&-task {

		flex-direction: column;
		align-items: baseline;
		font-size: 18px;

		img {
			max-width: 200px;
			opacity: .1;
		}
	}

	&-map {
		border: 3px dashed $gray-300;
		color: $gray-300;
		font-weight: 700;
		font-size: 24px;

		min-height: 300px;

		justify-content: center;
		align-items: center;

		position: absolute;
		z-index: 2;
		width: calc(100% - 30px);
		background: #fff;
	}

	&-image {
		@extend .placeholder-map;

		border: none;

		cursor: pointer;
		width: 100%;
		height: 100%;
		min-height: 0;

		background: $gray-100;

		&:hover {
			background: $gray-200;
			color: $gray-400;
		}
	}
}
