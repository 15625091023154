.BooksList {
	.description-holder {
		max-height: 26px;
		overflow: hidden;
	}
}

.BookFilter {
	display: flex;
	align-items: center;

	span {
		margin-right: 20px;
		font-weight: 500;
		color: $primary;
	}
}
