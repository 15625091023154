@import "book-tabs";
@import "book-list";
@import "information-tab";
@import "gallery-tab";
@import "task/tasks-tab";
@import "question/question";
@import "slide/slide-dropdown";
@import "slide/slide-contents";
@import "slide/slide-detail";
@import "slide/slide-information";

.BookPage {

	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;

	@include media-breakpoint-down(md) {
		display: block;
		overflow: scroll;
		height: auto;
	}

	&-Header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: $bp-header-height;
		padding: 0 30px;

		h3 {
			margin-bottom: 0;
		}

		.buttons {
			display: flex;
		}

		@include media-breakpoint-down(sm) {
			flex-direction: column;
			align-items: flex-start;
		}
	}

}

.BookNavigation {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: $bp-content-breadcrumbs-height;
	padding: 15px 30px 0 0;

	@include media-breakpoint-down(md) {
		height: auto;
		padding: 15px 30px 15px 0;
		flex-direction: column;
		align-items: flex-start;
	}

	button {
		text-transform: lowercase;
	}

	.breadcrumbs {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		flex-basis: 60%;
		max-width: 60%;

		// 1 line
		height: 26px;
		overflow: hidden;

		@include media-breakpoint-down(md) {
			margin-bottom: 10px;
			height: auto;
		}

		.item {
			max-width: 150px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;

			&.single {
				max-width: 100%;
			}

			&:last-child {
				font-weight: 700;
			}
		}
	}

	.actions {
		text-align: right;
	}
}
