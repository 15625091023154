@import "variables";
@import '~bootstrap/scss/bootstrap.scss';

// Components
@import "components/navigation";
@import "components/box";
@import "components/confirmation-modal";
@import "components/pagetitle";
@import "components/editor";
@import "components/sortableitem";
@import "components/submit-button";
@import "components/floating-alert";
@import "components/loading";
@import "components/book-serach";
@import "components/pagination";
@import "components/breadcrumbs";
@import "components/gallery-modal";
@import "components/image-block";

// Pages
@import "pages/login";
@import "pages/home";
@import "pages/users";
@import "pages/book-page/book-page";
@import "pages/books-list";

// Common
@import "partial/default";
@import "partial/buttons";
@import "partial/table";
@import "partial/dropdown";
@import "partial/modal";
@import "partial/smap";
@import "partial/placeholder";
@import "partial/card";
