.FloatingAlert {

	position: fixed;
	top: 15px;
	left: 50%;
	transition: opacity .5s ease-in-out;
	transform: translateX(-50%);
	opacity: 0;
	pointer-events: none;

	z-index: 10000;

	border-radius: 6px;
	text-align: center;
	font-weight: 500;

	padding: 10px 30px;

	width: 400px;
	max-width: 100%;
	box-shadow: 0 0 12px 6px rgba(0, 0, 0, 0.05);

	&.show {
		opacity: 1;
		pointer-events: auto;
	}

	&.error {
		background: lighten($danger, 40%);
		color: $danger;
		border: 1px solid lighten($danger, 30%);
	}

	&.success {
		background: lighten($success, 50%);
		color: $success;
		border: 1px solid lighten($success, 30%);
	}
}

@keyframes hide {
	to {
		transform: translate(100%, 0);
		right: 0;
	}
}
