.Pagination {
	.recordsOnPageInput {

		display: flex;
		justify-content: center;
		align-items: center;

		input {
			width: auto;
			margin-left: 1em;
		}
	}
}
