.Users {

	&-User {
		&-Roles {
			display: flex;
			justify-content: center;

			button + button {
				margin-left: 10px;
			}
		}
	}
}

.institution {
	display: flex;
	align-items: baseline;
	padding-top: 2px;

	h1, h2, h3, h4 {
		margin-bottom: 0;
	}

	span, .btn-group {
		margin-left: 10px;
	}

	span {
		font-size: 22px;
		font-weight: 700;
		display: block;
	}

	@include media-breakpoint-down(md) {
		flex-direction: column;

		span, .btn-group {
			margin-left: 0;
		}
	}
}
