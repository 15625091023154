.SlideTabs {

  flex-grow: 1;
  margin-right: -30px;
  margin-left: -30px;
  margin-bottom: -30px;

  display: flex;
  flex-direction: column;

  .tab-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-top: 1px solid $border-color;
  }

  .tab-pane {
    padding: 30px;
  }

  .nav {
    padding: 0 30px;
    position: relative;
    bottom: -1px;

    &-item {

      .nav-link {
        border: 1px solid $border-color;
        margin-left: -1px;
        position: relative;
        color: $body-color;
        background: $gray-100;
        font-size: 14px;

        height: $bp-content-tabs-height;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
          border-bottom-color: #ffffff;
          background: #ffffff;
        }

        &.disabled {
          color: $border-color;
          pointer-events: none;
        }

        &:not(.active) {
          &:hover, &:active, &:focus {
            border-color: $border-color;
            background: $gray-100;
          }
        }

        &:focus {
          outline: none;
        }
      }

      &:first-child {
        .nav-link::before {
          position: absolute;
          content: '';
          width: 31px;
          height: calc(100% + 1px);
          top: 0;
          right: 100%;
          border-bottom: 1px solid $border-color;
        }
      }

      &:last-child {
        .nav-link::after {
          position: absolute;
          content: '';
          width: 30px;
          height: calc(100% + 1px);
          top: 0;
          left: 100%;
          border-bottom: 1px solid $border-color;
        }
      }
    }
  }
}
