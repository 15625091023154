.imageQuestion {
  .image-row {
    width: 100%;

    .img {
      width: 100%;
      height: 120px;
      background-position: center !important;
      background-size: cover !important;
    }
  }

  .note {
    margin-bottom: 20px;
    font-size: 12px;
    font-style: italic;
  }
}
