.Breadcrumbs {
	padding: 2px 30px;
	background: $gray-200;
	height: $bp-breadcrumbs-height;

	display: flex;
	align-items: center;

	position: relative;
	top: 0;
	left: 0;
	width: 100%;

	font-size: 14px;
	font-weight: 500;

	svg {
		margin: 0 1em;
	}

	&-Item, svg {
		color: $gray-600;
	}

	&-Item {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
