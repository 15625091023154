.Editor {

	background: #fff;
	border: 1px solid $border-color;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		margin-right: 0 !important;
	}

	@include media-breakpoint-up(md) {
		display: flex;
		flex-grow: 1;
	}

	&-Left {
		padding: 0;
		background: $gray-100;
		position: relative;

		@include media-breakpoint-up(md) {
			position: absolute;
			top: 0;
			left: 15px;
			height: 100%;
			width: calc(100% - 30px);

			border-right: 1px solid $border-color;
		}

		ul {
			padding: 0;
			margin: 0;
		}

		> ul {

			height: 100%;

			overflow-y: scroll;
			overflow-x: hidden;

			position: relative;

			padding-bottom: 120px;

			@include media-breakpoint-down(sm) {
				max-height: 300px;
				border-bottom: 2px solid $border-color;
			}
		}

		.item-w-children {

			.children {
				transition: $transition-collapse;
				border-top: 1px solid $border-color;

				li {
					color: $secondary;

					.title {
						padding-left: 30px;
					}

					&:hover {
						background: $gray-200;
					}

					&:not(:first-child) {
						border-color: rgba($border-color, 0.2);
					}
				}

				&.hide {
					height: 0;
					overflow: hidden;
				}
			}
		}

		&__item {
			background: #fff;
			list-style: none;

			display: flex;
			position: relative;

			border: 1px solid $border-color;
			border-left: none;

			margin: -1px -1px -1px 0;

			overflow: hidden;

			&.dragging {
				box-shadow: $box-shadow;

				&-child {
					background: $gray-100;

					.title {
						padding-left: 30px;
					}
				}
			}

			&.active {
				border-left: 3px solid $accent-color;
				color: $accent-color !important;
				font-weight: 500;
			}

			&:hover {
				cursor: pointer;
				background: $gray-200;
			}

			.actions {
				display: flex;
				width: 40px;

				> div {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						color: $gray-500;
						transition: $transition-base;
					}
				}

				&:hover {
					svg {
						color: $gray-600;
					}
				}

				&-toggle {
					&.show svg {
						transform: rotate(180deg);
					}
				}
			}

			.title {
				flex: 1;
				position: relative;
				padding: 15px;

				span {
					height: 24px;
					overflow: hidden;
					display: block;
				}

				&:hover {
					background: $gray-200;
				}
			}
		}
	}

	&-Right {
		height: 100%;
		display: flex;
		flex-direction: column;
		position: relative;

		@include media-breakpoint-down(sm) {
			padding: 30px 0 30px 30px;
		}
	}

}
