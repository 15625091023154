.Gallery {

	&-Upload {
		padding: 30px;
		border-bottom: 1px solid $gray-300;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: $gallery-upload-height;

		&.disabled {
			opacity: 0.7;
			pointer-events: none;
		}

		strong {
			text-transform: none;
		}
	}

	&-Footer {
		height: $gallery-footer-height;
		padding: 0 30px;
		border-top: 1px solid $gray-300;

		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&-Items {
		flex: 1;
		padding: 30px;
		overflow-y: scroll;
		background: $gray-100;

		@include media-breakpoint-up(md) {
			max-height: calc(#{$bp-content-height} - #{$gallery-upload-height} - #{$gallery-footer-height});
		}
	}

	&-Item {
		text-align: center;
		margin-bottom: 20px;

		.image {
			position: relative;
			cursor: pointer;

			&:hover {
				opacity: .9;
			}

			.img {
				height: 120px;
				border: 1px solid $gray-300;
				background-size: cover !important;
				background-position: center !important;
				position: relative;
				z-index: 2;
			}

			.placeholder {
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				font-size: 32px;
				color: $nav-bg;
				opacity: 0.5;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.name {
			margin-top: 5px;
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.active-frame {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border: 5px solid $success;
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
			cursor: pointer;
			z-index: 3;

			.check-holder {
				width: 25px;
				height: 25px;
				overflow: hidden;
				background: $success;
				display: flex;
				justify-content: center;
				align-items: center;
				padding-left: 5px;
				padding-bottom: 5px;

				svg {
					color: #fff;
					font-size: 18px;
				}
			}
		}
	}
}
