.loginPage {
	background: #fff;
	border-top: 3px solid $primary;
	width: 380px;
	padding: 30px;
	margin: auto;
	position: relative;

	&-resetLink {
		margin-top: 30px;
		text-align: center;

		a {
			color: $primary;
			font-weight: 700;
			border-bottom: 1px solid $primary;

			&:hover {
				text-decoration: none;
				cursor: pointer;
				border-bottom-color: transparent;
			}
		}

		span {
			display: block;
			margin-top: 20px;
			color: $success;
		}
	}

	.resetSuccess {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.SubmitButton {
		margin-top: 30px;
	}

	.backToLogin {
		position: absolute;
		right: 100%;
		top: 30px;
		padding-right: 50px;

		a {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			&:hover {
				text-decoration: none;

				.icon {
					background: lighten($primary, 10%);
				}

				span {
					color: lighten($primary, 10%);
				}
			}

			.icon {
				width: 50px;
				height: 50px;
				background: $primary;
				border-radius: 50%;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 32px;
				margin-bottom: 10px;
			}

			span {
				color: $primary;
				font-weight: 700;
				font-size: 22px;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		width: 90%;
	}
}
