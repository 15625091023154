.InformationTab {
	padding: 30px;
	display: flex;

	@include media-breakpoint-down(md) {
		flex-direction: column;
	}

	form {
		flex: 1;
	}

	label {
		width: 100%;
	}

	.book-thumbnail {
		width: 400px;
		min-width: 400px;
		max-width: 30%;
		margin-left: 30px;
		display: flex;

		@include media-breakpoint-down(md) {
			width: 100%;
			min-width: 0;
			max-width: 100%;
			margin: 0;
		}
	}
}

.LanguageDropdown {
	width: 100%;

	button.btn-primary {
		display: flex;
		justify-content: space-between;
		align-items: center;

		height: calc(1.5em + 0.75rem + 2px);
		padding: 0.375rem 0.75rem;
		background: #fff;
		border: 1px solid $border-color;
		color: $body-color;
		width: 100%;
		font-family: $font-family-base;
		font-size: $font-size-base;
		font-weight: 300;

		&:hover, &:active, &:focus {
			background-color: $gray-100 !important;
			border-color: $border-color !important;
			color: $body-color !important;
		}
	}

	&.show {
		button.btn-primary {
			background-color: $gray-100;
			border-color: $border-color;
			color: $body-color;
		}

		.dropdown-menu {
			&.show {
				width: 100%;
				margin-top: -1px;
				border-color: $border-color;
			}
		}

		.dropdown-item {
			font-weight: 300;

			&:active {
				background-color: $gray-100 !important;
				color: $body-color !important;
			}
		}
	}
}
