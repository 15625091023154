.table {
	margin: 2em 0 4em;
	table-layout: fixed;

	thead {
		background: $primary;
		color: #fff;
		text-align: center;
	}

	td, th {
		vertical-align: middle;
		overflow: hidden;
	}

	th {
		&:first-child {
			width: 60px;
		}

		@include media-breakpoint-up(lg) {
			&:nth-child(4), &:nth-child(5) {
				width: 140px;
			}

			&:nth-child(6) {
				width: 180px;
			}
		}
	}

	&.users th {
		@include media-breakpoint-up(lg) {
			&:nth-child(2), &:nth-child(3) {
				width: 220px;
			}
		}
	}
}
