.Navigation {
	height: 100vh;
	width: 180px;

	background: $nav-bg;
	color: $nav-link-color;

	display: flex;
	flex-direction: column;

	position: relative;

	@include media-breakpoint-down(md) {
		width: 100vw;
		height: auto;
		position: fixed;
		top: 0;
		z-index: 10;
	}

	.institution-name {
		font-size: 18px;
		font-weight: 700;
		padding: 30px 15px;
		color: #fff;
		background: $accent-color;
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		> ul {
			padding-left: 1em;

			a, button {
				color: lighten($nav-bg, 30%);

				&:hover {
					color: lighten($nav-bg, 50%);
				}
			}
		}
	}

	a, button {
		color: darken(#fff, 40%);
		text-decoration: none;
		padding: 10px 16px 10px 3em;
		display: flex;
		align-items: center;
		position: relative;
		width: 100%;

		transition: color .2s ease;

		svg {
			position: absolute;
			left: 16px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			color: #fff;
			text-decoration: none;
		}

		&.active:not(.logo) {
			position: relative;
			color: white;
			font-weight: 500;

			@include media-breakpoint-up(lg) {
				&::after {
					content: '';
					width: 10px;
					height: 20px;

					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);

					border-right: 10px solid $body-bg;
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
				}
			}
		}
	}

	.logo {
		padding: 16px;
		font-weight: 800;
		font-size: 1.5em;
		opacity: .7;

		@include media-breakpoint-down(md) {
			padding: .5em 16px;
		}
	}

	.menu {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding-top: 1em;

		@include media-breakpoint-down(md) {
			height: 0;
			flex: auto;
			overflow: hidden;
			padding-top: 0;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;

			&.show {
				height: auto;
				flex: 1;
				overflow: auto;
				padding-top: 1em;
				background: $nav-bg;
			}
		}

		&-main {
			flex: 1;
		}

		&-footer {
			margin-top: 2em;

			li {
				border-top: 1px solid rgba($border-color, 0.5);
			}

			a, button {
				justify-content: center;
				padding: 10px 0;
				width: 100%;

				svg {
					position: relative;
					top: 0;
					left: 0;
					margin-left: 10px;
					margin-right: 0;
					margin-top: 15px;
				}
			}
		}
	}

	.nav-toggle {

		cursor: pointer;
		position: relative;
		width: 3em;
		height: 19px;

		&-holder {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		span {
			display: block;
			height: 3px;
			width: 2em;
			background: #fff;
			opacity: 1;

			position: absolute;
			left: 0;

			transition: all .2s ease;

			&:nth-child(1) {
				top: 0;
			}
			&:nth-child(2) {
				top: 8px;
			}
			&:nth-child(3) {
				top: 16px;
			}
		}

		&.cross {
			span {
				top: 0.6em;
				&:nth-child(1) {
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					opacity: 0;
				}
				&:nth-child(3) {
					transform: rotate(-45deg);
				}
			}
		}

		@include media-breakpoint-up(lg) {
			display: none;
		}
	}

	@include media-breakpoint-down(md) {
		.logo-toggle {
			display: flex;
			width: 100%;
			justify-content: space-between;
		}
	}
}
