.SubmitButton {
	text-align: center;

	.loading {
		span {
			margin-right: 10px;
		}
	}

	&.disabled {
		opacity: 0.2;
		pointer-events: none;
	}
}
