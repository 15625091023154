.Loading {
	font-size: 22px;
	font-weight: 700;
	color: $primary;
	justify-content: center;
	align-items: center;
	display: none;

	span {
		margin-right: 10px;
	}

	&.show {
		display: flex;
	}

	&.sm {
		font-size: 18px;
	}
}
