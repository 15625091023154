html, body {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

body {
	font-variant-numeric: lining-nums;
	font-feature-settings: "lnum";
	-moz-font-feature-settings: 'lnum=1';
	-webkit-font-feature-settings: 'lnum';
	-o-font-feature-settings: 'lnum';
}

main {
	flex: 1;
	display: flex;
	height: 100vh;
	overflow: hidden;

	@include media-breakpoint-down(md) {
		flex-direction: column;
		margin-top: 60px;
	}
}

section {
	flex: 1;
	padding: 30px;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	position: relative;
}

textarea {
	width: 100%;
	min-height: 200px;
	border: 1px solid $border-color !important;
}

h1 {
	font-size: $h1-font-size;
	font-weight: $h1-font-weight;
	color: $primary;
	margin-bottom: .5em;
}

h2 {
	font-size: $h2-font-size;
	font-weight: $h2-font-weight;
	margin-bottom: .5em;
}

h3 {
	font-size: $h3-font-size;
	font-weight: $h3-font-weight;
	margin-bottom: .5em;
}

h4 {
	font-size: 22px;
	font-weight: 700;
	color: $cyan;
	margin-bottom: 1em;
	margin-top: 30px;
}

h5 {
	padding: 30px 15px 0;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: $accent-color;
	font-size: 16px;
	font-weight: 300;
}

strong {
	font-weight: 700;
}

iframe {
	border: none;
}
