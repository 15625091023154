.BookSearch {
	position: relative;

	input {
		background: transparent;
		border: none;
		border-bottom: 2px solid rgba($input-placeholder-color, .3);
		padding-left: 0;
		padding-right: 40px;

		&:focus {
			box-shadow: none;
			background: transparent;
			border-bottom-color: rgba($input-placeholder-color, .3);
		}
	}

	svg {
		position: absolute;
		top: 0;
		right: 0;
		font-size: 22px;
		color: rgba($input-placeholder-color, .3);
	}
}
