.modal {

	&-dialog {
		margin: 0 auto;
	}

	&-header {
		background: $gray-200;
		border-bottom: 1px solid $gray-300;
	}

	&-content {
		background-clip: border-box;
	}

	&-body {
		text-transform: capitalize;
	}

	&-footer {
		border: none
	}

	&-footer {
		justify-content: space-between;
	}

	&.fade &-dialog, &.show &-dialog {
		top: 50%;
		transform: translateY(-50%);
	}
}

.react-confirm-alert {
	&-body-element &-overlay {
		background: rgba(#000, .5);
		z-index: 999;

		.modal-dialog {
			top: 0;
			transform: none;
		}
	}
}
