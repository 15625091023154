.Homepage {

	&-Title {
		font-size: 52px;
		font-weight: 800;
		padding-bottom: 30px;
		overflow: hidden;

		text-align: center;

		transform: translateY(-100%);
		opacity: 0;

		transition: opacity 1s ease-in-out;
		animation: welcome-fade 1s forwards;

		@include media-breakpoint-down(md) {
			font-size: 1.5em;
		}
	}

	&-Teaser {

		h3 {
			font-size: 18px;
			color: $body-color;
			text-align: left;
		}
	}

	&-Links {
		@include media-breakpoint-down(xs) {
			justify-content: center;
			margin-bottom: 3em;
		}
	}

	&-Link {
		width: 250px;
		height: 100px;
		font-size: 18px;

		margin: 15px;
		position: relative;
	}
}

@keyframes welcome-fade {
	0% {
		transform: translateY(-100%);
		opacity: 0;
	}
	100% {
		transform: none;
		opacity: 1;
	}
}
