@import "question-image";

.QuestionTypeLabel {

	display: inline-block;
	font-size: 12px;
	border-radius: 4px;
	padding: 0 5px;
	color: #ffffff;

	background: $secondary;
}

.Question {
	display: flex;
	flex-direction: column;

	&-checkboxes {
		margin: 30px 0;
	}
}

.question-drag {
	margin-left: 10px;
}
