button, .btn, .page-link, .btn-outline-secondary.active {
	&:focus, &:active {
		box-shadow: none !important;
		outline: none;
	}
	&:hover {
		cursor: pointer;
	}
}

.btn {
	&-accent {
		background: $cyan;
		color: #fff;

		&:hover {
			background-color: darken($cyan, 5%);
			border-color: darken($cyan, 5%);
			color: #fff;
		}
	}

	&-invisible {
		background: transparent;
		padding: 0;
		border: none;
	}

	&-a {
		display: flex;
		align-items: center;
		//border-bottom: 1px solid;
		opacity: .6;
		transition: $transition-base;
		padding: 0;
		cursor: pointer;

		&:hover {
			//border-bottom-color: transparent;
			opacity: 1;
		}
	}
}

.button {

	&-primary {
		svg {
			color: $cyan;
			padding-right: 5px;
			margin-right: 20px;
			transition: $transition-base;
		}

		&:hover {
			svg {
				color: darken($cyan, 10%);
			}
		}
	}
}

.button, button {
	background: transparent;
	border: none;
	padding: 0;

	&:focus {
		outline: none !important;
	}
}
