.BooksList {
  display: flex;
  flex-wrap: wrap;

  margin-left: -15px;
  margin-right: -15px;

  padding: 30px 0 40px;

  &-Header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .page-title {
      margin-bottom: 0;
      padding-right: 60px;
    }

    .BookSearch {
      flex: 1;
    }
  }

  .BookItem {
    width: 100%;
    min-height: 240px;
    overflow: hidden;
    background: #ffffff;
    margin: 15px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

    display: flex;

    @include media-breakpoint-up(lg) {
      width: calc(50% - 30px);
    }

    h3 {
      font-size: 22px;
    }

    &-image {
      min-width: 30%;
      width: 100px;
      max-width: 200px;

      display: flex;
      align-items: center;
      justify-content: center;

      .img {
        height: 100%;
        width: 100%;

        background-size: cover !important;
        background-position: center !important;

        background-color: $gray-200;
        color: $gray-400;
        font-weight: 700;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        text-align: center;
      }
    }

    &-desc {
      flex: 1;

      display: flex;
      flex-direction: column;

      max-width: 70%;
      width: calc(100% - 100px);

      h3 {
        max-height: 52px;
        overflow: hidden;
      }

      .header {
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
          flex: 1;
        }
      }

      > .text {
        flex-grow: 1;
        padding: 20px 30px;
      }

      p {
        margin-bottom: 0;
        overflow: hidden;
        font-size: 12px;
        max-height: 50px;
        padding-top: 5px;
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 30px 15px;
    }

    .state {
      font-weight: 700;

      .yes {
        color: $success;
      }

      .no {
        color: $danger;
      }
    }

    .tasks {
      color: $primary;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      .number {
        font-size: 12px;

        span {
          margin-right: 5px;
          font-size: 28px;
          font-weight: 700;
        }
      }
    }
  }
}
