$blue: #12355B;
$blue--light: #427AA1;
$grey--light: #88A0A8;
$dark: #343a40;

$nav-bg: $dark;
$nav-link-color: #fff;
$nav-link-active-color: $blue--light;

$accent-color: lighten($blue, 40%);
$border-color: #C6CBD1;

// heights
$bp-breadcrumbs-height: 25px;
$bp-header-height: 70px;
$bp-tab-height: 45px;
$bp-content-height: calc(100vh - #{$bp-breadcrumbs-height} - #{$bp-header-height} - #{$bp-tab-height});
$bp-content-breadcrumbs-height: 60px;
$bp-content-tabs-height: 30px;
$gallery-upload-height: 70px;
$gallery-footer-height: 70px;

// Bootstrap

$primary: $blue;
$light: #EBF2FA;

$font-family-base: 'Raleway', sans-serif;
$font-weight-base: 300;
$body-bg: $light;

$input-border-radius: 0;
$input-border-radius-lg: 0;
$input-bg: #fff;

$btn-border-radius: 0;
$btn-border-radius-sm: 0;

$h1-font-size: 36px;
$h1-font-weight: 800;
$h2-font-size: 36px;
$h2-font-weight: 700;
$h3-font-size: 26px;
$h3-font-weight: 700;
$h4-font-size: 14px;

$alert-border-radius: 0;

$modal-content-border-radius: 0;

$card-border-radius: 0;

$form-group-margin-bottom: 20px;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1600px
);
