.Images {
	flex: 1;
	display: flex;
	flex-direction: column;

	height: 100%;
	min-height: 350px;

	&-placeholder {
		flex: 1;
		background: $gray-100;
		border: 1px solid $border-color;

		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	&-image {
		flex: 1;
		cursor: pointer;
		position: relative;
		box-shadow: 0 0 6px 0 rgba(#000, .2);
		margin-bottom: 20px;

		&__cog {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-end;
			align-items: baseline;
			padding: 15px;

			opacity: 1;
			transition: $transition-base;
		}

		&__options {
			opacity: 0;
			transition: $transition-base;

			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(#000, .7);
			color: #fff;
			font-size: 24px;
			display: flex;
			justify-content: center;
			align-items: center;

			flex-direction: column;

			.btn {
				margin: 10px 0;
				width: 90%;
				max-width: 250px;
			}
		}

		&:hover {
			.Images-image__cog {
				opacity: 0;
			}
			.Images-image__options {
				opacity: 1;
			}
		}

		.img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-position: center !important;
			background-size: cover !important;
		}
	}
}
