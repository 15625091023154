.ConfirmationModal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 100;

	height: 0;
	opacity: 0;
	overflow: hidden;

	transition: opacity .5s ease;

	background: rgba(#000, .7);
	display: flex;
	justify-content: center;
	align-items: center;

	&.show {
		height: 100vh;
		opacity: 1;
	}

	&-Close {
		position: absolute;
		top: 10px;
		right: 30px;
		font-size: 24px;
		color: $grey--light;
		opacity: .5;
		transition: opacity .5s ease;

		&:hover {
			cursor: pointer;
			opacity: 1;
		}
	}

	&-Content {
		background: #fff;
		padding: 50px 30px 30px;
		text-align: center;
		font-size: 18px;
		font-weight: 500;
		min-width: 500px;
		max-width: 90%;
		position: relative;
	}

	&-Buttons {
		display: flex;
		margin-top: 30px;

		.btn {
			flex: 1;
			margin: 0 10px;
		}
	}
}
